/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import * as Sentry from "@sentry/react";
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import { createBrowserHistory } from "history";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { Suspense, useEffect, useState } from "react";
import { getI18n } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { compose } from "redux";
import { useIdleTimer } from "react-idle-timer/legacy";
import { Stack } from "@mui/material";
import { userPermissionStore } from "../../store/user-permissions-store";
import { clientFeatureStore } from "../../store/client-features-store";

import { ModalsProvider } from "@rooster/ui";
import { isEqual } from "lodash";
import { checkSmsAccountIsActive } from "@rooster/utils";
import { version } from "../../../package.json";
import * as TABLES from "../../constants/databaseTables";
import * as ROUTES from "../../constants/routes";
import { useMemoCompare } from "../../hooks/useMemoCompare";
import InContextEditor from "../../i18n/reactIntegration/InContextEditor/InContextEditor";
import { registerIcons } from "../../icons/register-icons";
import {
  actionTypes as ClientPreferenceAction,
  SET_CLIENT_INTERVIEW_STAGES_ACTION,
  SET_CLIENT_REMINDERS_ACTION,
  SET_CLIENT_TRIGGERS_ACTION
} from "../../reducers/additionalClientPreferencesReducer";
import { CLIENT_DOC_DATA_SET } from "../../reducers/clientDocDataReducer";
import { CLIENT_LOCATIONS_SET_ACTION } from "../../reducers/clientLocationsReducer";
import {
  SET_CONFERENCES_ENABLED_ACTION,
  SET_SMS_ENABLED_ACTION
} from "../../reducers/conferenceSelectionReducer";
import { SET_DOMAIN_DOC_ACTION } from "../../reducers/domainDocDataReducer";
import {
  SET_CLIENT_SETTINGS_ACTION,
  SET_WEBEX_DOMAIN_ACTION
} from "../../reducers/miscellaneousSettingsReducer";
import { I18N_EDIT_MODE_SET } from "../../reducers/sessionReducer";
import { addDomainDocs } from "../../reducers/slices/domains";
import {
  CONFERENCING_SET_ACTION,
  ROLES_SET_ACTION,
  SKILLS_SET_ACTION
} from "../../reducers/workdayDataReducer";
import {
  shouldInitializeLogRocket,
  shouldInitializeSentry
} from "../../runtimeConfig";
import ThemeProvider from "../../theme/ThemeProvider";
import { getClientDomainDocs } from "../../utilities/firestore/query/domains";
import {
  getConferencingOptionsFromFirestore,
  getLocationsFromFirestore,
  getRolesOptionsFromFirestore,
  getSkillsOptionsFromFirestore
} from "../../utilities/firestore/query/workdayData";
import RoosterLoader from "../@rooster/Loader/RoosterLoader";
import { AlertPopup } from "../AlertPopup/AlertPopup";
import InterviewersAvailability from "../@rooster/Availability/interviewers.availability";
import TagsList from "../Tags/tags.list.component";
import SandboxHeader from "../Navigation/Header/Sandbox/SanboxHeader";
import { withAuthentication } from "../Session";
import "./App.css";
import { lazyWithRetry } from "./lazyWithRetry";
import {
  useFetchMyPermissions,
  useClientFeatures
} from "../../hooks/usePermissions";
import dayjs from "dayjs";

const AboutPage = lazyWithRetry(() => import("../About"));
const AccountPage = lazyWithRetry(() => import("../Account"));
const AdminPage = lazyWithRetry(() => import("../Admin"));
const AnalyticsPage = lazyWithRetry(() => import("../Analytics"));
const InterviewersList = lazyWithRetry(
  () => import("../Interviewers/interviewers.list.component")
);

const Autobook = lazyWithRetry(() => import("../Autobook/index"));

const InterviewerPreferencesPage = lazyWithRetry(
  () => import("../InterviewerPreferences")
);
const MeetingLinkContainer = lazyWithRetry(
  () => import("../RoosterLinks/components/meetinglinks/MeetingLinkContainer")
);

const ClientMigrationPage = lazyWithRetry(() => import("../ClientMigration"));
const TranslationManagementPage = lazyWithRetry(
  () => import("../TranslationManagement")
);
const ConfigurationPage = lazyWithRetry(() => import("../Configuration"));
const ContactPage = lazyWithRetry(() => import("../Contact"));
const SchedulerContainer = lazyWithRetry(
  () => import("../containers/SchedulerContainer/SchedulerContainer")
);
const DashboardPage = lazyWithRetry(() => import("../JobApplications"));
const DocumentationPage = lazyWithRetry(() => import("../Documentation"));
const EmailTemplatesPage = lazyWithRetry(
  () => import("../EmailTemplates/index")
);
const EmailTemplatesEditPage = lazyWithRetry(
  () => import("../EmailTemplatesEdit")
);
const ReportsPage = lazyWithRetry(() => import("../Reports"));
const GoogleDisclosurePage = lazyWithRetry(() => import("../GoogleDisclosure"));
const GoogleScopesPage = lazyWithRetry(() => import("../GoogleScopes"));
const HomePage = lazyWithRetry(() => import("../Home"));
const JobApplicationPage = lazyWithRetry(() => import("../JobApplication"));
const JobUnavailablePage = lazyWithRetry(() => import("../JobUnavailable"));
const ClientDashboardPage = lazyWithRetry(() => import("../ClientDashboard"));
const LinkUnavailablePage = lazyWithRetry(() => import("../LinkUnavailable"));
const Navigation = lazyWithRetry(() => import("../Navigation"));
const NotAuthorizedPage = lazyWithRetry(() => import("../NotAuthorized"));
const PageNotFoundPage = lazyWithRetry(() => import("../PageNotFound"));
const PasswordForgetPage = lazyWithRetry(() => import("../PasswordForget"));
const PreferencesPage = lazyWithRetry(() => import("../Preferences"));
const PrivacyPolicyPage = lazyWithRetry(() => import("../PrivacyPolicy"));
const ProtectedAdminRoute = lazyWithRetry(
  () => import("../Session/ProtectedAdminRoute")
);
const ProtectedRoute = lazyWithRetry(() => import("../Session/ProtectedRoute"));
const PublicOnlyRoute = lazyWithRetry(() => import("../Session/PublicRoute"));
const OpenRoute = lazyWithRetry(() => import("../Session/OpenRoute"));
const RespondPage = lazyWithRetry(() => import("../Respond"));
const SettingsPage = lazyWithRetry(() => import("../Settings"));
const LogInPage = lazyWithRetry(() => import("../LogIn"));
const SignInPage = lazyWithRetry(() => import("../SignIn"));
const LogOutPage = lazyWithRetry(() => import("../LogOut"));
const SupportPage = lazyWithRetry(() => import("../Support"));
const TermsAndConditionsPage = lazyWithRetry(
  () => import("../TermsAndConditions")
);
const ThankYouPage = lazyWithRetry(() => import("../ThankYou"));

const TokenPage = lazyWithRetry(() => import("../Token"));
const DemoVideoPage = lazyWithRetry(() => import("../Video/demo"));
const TrainingVideoPage = lazyWithRetry(() => import("../Video/training"));
const ZoomSupportPage = lazyWithRetry(() => import("../ZoomSupport"));
const WebexSupportPage = lazyWithRetry(() => import("../WebexSupport"));
const GAListener = lazyWithRetry(() => import("./gaListener"));

const InterviewsCalendar = lazyWithRetry(() => import("../CalendarView"));

export const history = createBrowserHistory();

if (shouldInitializeLogRocket()) {
  LogRocket.init("tvewer/rooster", { release: version });
  setupLogRocketReact(LogRocket);
}

if (shouldInitializeSentry()) {
  Sentry.init({
    dsn: "https://b0a58e5d16214ce683748831d3e1a5f9@sentry.io/1731038",
    release: `rooster@${version}`,
    environment: import.meta.env.REACT_APP_ENV,
    integrations: [
      new TracingIntegrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
      })
    ],
    attachStacktrace: true,
    autoSessionTracking: true,
    normalizeDepth: 10,
    tracesSampleRate: 0.25
  });
}

// handle icon css, import, and library config
registerIcons();

const App = (props) => {
  const i18n = getI18n();
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.sessionState.authUser);
  const i18nEditMode = useSelector((state) => state.sessionState.i18nEditMode);
  const [timeoutValue, setTimeoutValue] = useState(60 * 60 * 10000);
  const {
    firebase: { db, remoteConfig } = {}
  } = props;
  // const [domainExpiryMessage, setDomainExpiryMessage] = useState("");
  const [domainExpiryDate, setDomainExpiryDate] = useState("");

  const selClientDoc = useSelector(
    (state) => state.clientDocDataState.clientDocData
  );

  const memoedTimeoutValue = useMemoCompare(timeoutValue, isEqual);
  const savePermissions = userPermissionStore((state) => state.savePermissions);
  const setClientFeatures = clientFeatureStore(
    (state) => state.setClientFeatures
  );

  const onFetchMyPermissionSuccess = (permissionsData) => {
    savePermissions(permissionsData);
  };
  const onClientFeaturesSuccess = (clientFeatureData) => {
    setClientFeatures(clientFeatureData);
  };

  useFetchMyPermissions({
    onFetchMyPermissionSuccess,
    enabled: !!authUser?.client
  });
  useClientFeatures({
    onClientFeaturesSuccess,
    enabled: !!authUser?.client
  });

  const { start, reset, pause } = useIdleTimer({
    onIdle: () => {
      pause();
      props?.firebase?.auth?.signOut();
    },
    timeout: memoedTimeoutValue,
    throttle: 500,
    crossTab: true,
    leaderElection: true,
    syncTimers: 2000,
    startOnMount: false,
    startManually: true
  });

  // check if ada is enabled and import the css file accordingly
  useEffect(() => {
    const root = document.querySelector(":root");
    if (authUser?.ada) {
      root.style.setProperty("--ada-color", "black");
      root.style.setProperty("--ada-bg-color", "lightblue");
    }
  }, [authUser?.ada]);

  useEffect(() => {
    if (!authUser?.client) {
      return () => null;
    }

    const clientDoc = db.collection(TABLES.CLIENTS).doc(authUser.client);

    const observer = clientDoc.onSnapshot(
      (clientDocSnapshot) => {
        if (!clientDocSnapshot.exists) {
          console.error(`clientDoc doesn't exist: ${authUser.client}`);
        } else {
          const clientDocData = {
            id: authUser.client,
            ...clientDocSnapshot.data()
          };

          const {
            zoom: { conference: zoomEnabled } = {},
            webex: { conference: webexEnabled, domain: webexDomain = "" } = {},
            eightbyeight: { conference: eightByEightEnabled } = {},
            msteams: { conference: msTeamsEnabled } = {},
            google: {
              conference: googleMeetEnabled,
              calendar: googleCalendarEnabled
            } = {},
            sms
          } = clientDocData?.integrations ?? { zoomEnabled: false };

          const {
            invitations: smsInvitations = false,
            notifications: smsNotifications = false,
            reminders: smsReminders = false
          } = sms ?? {};

          const smsEnabled =
            checkSmsAccountIsActive(clientDocData?.integrations?.sms) &&
            (smsInvitations || smsNotifications || smsReminders);

          const clientSettings = clientDocData?.settings;

          if (clientSettings?.activity_timeout_duration > 0) {
            setTimeoutValue(clientSettings?.activity_timeout_duration * 1000);
          } else {
            pause();
          }

          props.onSetConferencesEnabled({
            zoom: !!zoomEnabled,
            webex: !!webexEnabled,
            eightbyeight: !!eightByEightEnabled,
            msteams: !!msTeamsEnabled,
            meet: googleMeetEnabled && googleCalendarEnabled
          });
          props.onSetSmsEnabled(smsEnabled);
          props.onSetWebexDomain(webexDomain);
          props.onSetClientSettings(clientSettings);
          props.onSetClientDocData(clientDocData);
        }
      },
      (err) => console.error(err?.toString())
    );

    return () => observer();
  }, [authUser, db, props]);

  useEffect(() => {
    if (timeoutValue > 0) {
      reset();
      start();
    }
  }, [memoedTimeoutValue]);

  useEffect(() => {
    if (authUser?.email) {
      const domain = authUser?.email?.split("@")?.[1] ?? "";
      const domainDoc = db.collection(TABLES.DOMAINS).doc(domain);
      const observer = domainDoc?.onSnapshot(
        (domainDocSnapshot) => {
          if (!domainDocSnapshot.exists) {
            console.error(`Domain Doc for domain ${domain}, doesn't exist`);
          }
          const domainDocData = domainDocSnapshot.data() ?? {};
          props.onSetDomainDoc(domainDocData);

          // code to display banner for expiring client token
          const domExpiryDate = domainDocData?.clientSecretExpiryDate;

          if (domExpiryDate) {
            const expiryDate = dayjs.unix(domExpiryDate);
            const now = new Date();

            const differenceInMonths = expiryDate.diff(now, "month", true);

            if (differenceInMonths <= 1) {
              const expiryDateLocaleString = dayjs
                .unix(domExpiryDate)
                .format("MMMM DD, YYYY");
              setDomainExpiryDate(expiryDateLocaleString);
            } else {
              setDomainExpiryDate("");
            }
          }
        },
        (err) => console.error(err?.toString())
      );

      return () => observer();
    }
  }, [authUser, db, props]);

  useEffect(() => {
    if (!authUser?.client || !db) {
      return;
    }

    Promise.all([
      getRolesOptionsFromFirestore(db, authUser.client),
      getSkillsOptionsFromFirestore(db, authUser.client),
      getConferencingOptionsFromFirestore(db, authUser.client),
      getClientDomainDocs(authUser.client)
    ])
      .then(([roles, skills, conferencing, domainDocs]) => {
        props.onSetRoles(roles);
        props.onSetSkills(skills);
        props.onSetConferencing(conferencing);
        dispatch(addDomainDocs(domainDocs));
      })
      .catch((err) => console.error(err?.message));
  }, [db, authUser?.client]);

  useEffect(() => {
    if (!authUser?.client || !db) {
      return;
    }

    getLocationsFromFirestore(db, authUser.client)
      .then((locations) => {
        props.onSetClientLocations(locations);
      })
      .catch((err) => console.error(err?.message));
  }, [db, authUser?.client]);

  useEffect(() => {
    if (!authUser?.client) {
      return;
    }

    const additionalClientReminderPreferencesDoc = db
      .collection(TABLES.CLIENTS)
      .doc(authUser.client)
      .collection(TABLES.PREFERENCES)
      .doc(TABLES.REMINDERS);

    const additionalClientTriggerPreferencesDoc = db
      .collection(TABLES.CLIENTS)
      .doc(authUser.client)
      .collection(TABLES.PREFERENCES)
      .doc(TABLES.TRIGGERS);

    const observer = additionalClientReminderPreferencesDoc.onSnapshot(
      (additionalClientReminderPreferencesDocSnapshot) => {
        if (additionalClientReminderPreferencesDocSnapshot.exists) {
          const reminders =
            additionalClientReminderPreferencesDocSnapshot.data();

          props.onSetClientReminderPreferences(reminders);
        }
      },
      (err) => console.error(err)
    );

    additionalClientTriggerPreferencesDoc.onSnapshot(
      (additionalClientTriggerPreferencesDocSnapshot) => {
        if (additionalClientTriggerPreferencesDocSnapshot.exists) {
          const triggers = additionalClientTriggerPreferencesDocSnapshot.data();
          props.onSetClientTriggerPreferences(triggers);
        }
      },
      (err) => {
        props.onSetClientTriggerPreferences([]);
        console.error(err);
      }
    );

    return () => observer();
  }, [authUser, db, props]);

  useEffect(() => {
    if (!authUser?.client) {
      return;
    }

    const interviewStagesDocRef = db
      .collection(TABLES.CLIENTS)
      .doc(authUser.client)
      .collection(TABLES.MAPPINGS)
      .doc(TABLES.INTERVIEW_STAGES);

    const observer = interviewStagesDocRef.onSnapshot(
      (interviewStagesDocSnapshot) => {
        if (interviewStagesDocSnapshot.exists) {
          const stages = interviewStagesDocSnapshot.data() ?? {};
          const normalizedStages = Object.entries(stages ?? {})
            ?.map(([stage, steps]) => ({
              stage,
              steps: Object.values(steps) ?? []
            }))
            ?.reduce((acc, { stage, steps }) => {
              acc[stage] = steps;
              return acc;
            }, {});

          props.onSetClientInterviewStages(stages ? normalizedStages : stages);
        }
      },
      (err) => console.error(err)
    );

    return () => observer();
  }, [authUser, db, props]);

  const memoizedAuthUserId = useMemoCompare(
    authUser?.id ?? null,
    (prev, next) => prev === next
  );

  useEffect(() => {
    const updateRemoteConfig = async () => {
      if (remoteConfig && memoizedAuthUserId) {
        remoteConfig.settings = {
          fetchTimeoutMillis: 60000,
          minimumFetchIntervalMillis: 0
        };

        try {
          await remoteConfig.fetchAndActivate();
        } catch (e) {
          console.error("Error fetching remote config values:", e);
        }

        remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
      }
    };
    updateRemoteConfig();
  }, [memoizedAuthUserId]); // the effect runs whenever memoizedAuthUserId changes

  const setEditMode = () => {
    window.isEditMode = !i18nEditMode;
    i18n.reloadResources(i18n.language); // force reload current language
    dispatch({ type: I18N_EDIT_MODE_SET, payload: !i18nEditMode });
  };

  useEffect(() => {
    if (!authUser?.client) {
      return () => null;
    }
    const additionalClientAutomationPreferencesDoc = db
      .collection(TABLES.CLIENTS)
      .doc(authUser.client)
      .collection(TABLES.PREFERENCES)
      .doc(TABLES.AUTOMATION);
    const observer = additionalClientAutomationPreferencesDoc.onSnapshot(
      (snapshot) => {
        if (snapshot.exists) {
          const automationRules = snapshot.data();
          props.onSetClientAutomationPreferences(automationRules);
        }
      },
      (err) => console.error(err?.toString())
    );

    return () => observer();
  }, [authUser]);

  return (
    <Suspense
      fallback={
        <div className="d-flex justify-content-center vh-100 vw-100 align-content-center">
          <RoosterLoader height="100vh" />
        </div>
      }
    >
      <Router history={history}>
        <GAListener trackingId={import.meta.env.REACT_APP_GA_TRACKING_ID}>
          {i18nEditMode === true && (
            <InContextEditor editModeHandler={setEditMode} props={props} />
          )}
          <div style={i18nEditMode ? { marginBottom: "16em" } : {}}>
            <ThemeProvider>
              <ModalsProvider>
                <Stack
                  direction="column"
                  spacing={0}
                  sx={{
                    height: "100vh",
                    overflow: "auto"
                  }}
                >
                  {authUser?.client && selClientDoc?.id && (
                    <>
                      <Navigation
                        location={props}
                        editMode={setEditMode}
                        isEditMode={i18nEditMode}
                      />
                      <SandboxHeader
                        authUser={authUser}
                        domainExpiryDate={domainExpiryDate}
                      />
                    </>
                  )}

                  <Switch>
                    <OpenRoute path={ROUTES.TOKEN} component={TokenPage} />
                    <OpenRoute path={ROUTES.RESPOND} component={RespondPage} />
                    <PublicOnlyRoute path={ROUTES.HOME} component={HomePage} />
                    <PublicOnlyRoute
                      path={ROUTES.LOGIN}
                      component={LogInPage}
                    />
                    <PublicOnlyRoute
                      path={ROUTES.SIGNUP}
                      component={SignInPage}
                    />
                    <PublicOnlyRoute
                      path={ROUTES.SIGNIN}
                      component={SignInPage}
                    />
                    <PublicOnlyRoute
                      path={ROUTES.SIGNOUT}
                      component={LogOutPage}
                    />
                    <PublicOnlyRoute
                      path={ROUTES.LOGOUT}
                      component={LogOutPage}
                    />
                    <PublicOnlyRoute
                      path={ROUTES.PASSWORD_FORGET}
                      component={PasswordForgetPage}
                    />
                    <Route path={ROUTES.ACCOUNT} component={AccountPage} />
                    <ProtectedRoute
                      path={ROUTES.PREFERENCES}
                      component={PreferencesPage}
                    />
                    <Route
                      exact // This is required to prevent the route from matching the /jobs/:id route
                      path={ROUTES.APPLICATION_ID}
                      component={JobApplicationPage}
                    />
                    <Route
                      path={ROUTES.INTERVIEWERPREFERENCES}
                      component={InterviewerPreferencesPage}
                    />
                    <Redirect
                      exact
                      from={ROUTES.APPLICATION}
                      to={ROUTES.JOBS}
                    />
                    <ProtectedRoute
                      path={ROUTES.EMAILTEMPLATES}
                      component={EmailTemplatesPage}
                    />
                    <Route
                      path={ROUTES.EMAILTEMPLATESEDIT}
                      component={EmailTemplatesEditPage}
                    />

                    <OpenRoute path="/autobook" component={Autobook} />

                    <Route
                      path={ROUTES.SCHEDULER_BASE}
                      component={SchedulerContainer}
                    />

                    <ProtectedRoute
                      path={ROUTES.INTERVIEWS_CALENDAR}
                      component={InterviewsCalendar}
                    />

                    <ProtectedRoute
                      path={ROUTES.INTERVIEWERSDASHBOARD}
                      component={InterviewersList}
                    />

                    <ProtectedRoute
                      path={ROUTES.INTERVIEWERS_AVAILABILITY}
                      component={InterviewersAvailability}
                    />

                    <ProtectedRoute
                      path={ROUTES.TAGS_DASHBOARD}
                      component={TagsList}
                    />

                    <Route path={ROUTES.REPORTS} component={ReportsPage} />

                    <ProtectedAdminRoute
                      path={ROUTES.USERS}
                      component={AdminPage}
                    />
                    <ProtectedAdminRoute
                      path={ROUTES.SETTINGS}
                      component={SettingsPage}
                    />

                    <ProtectedRoute
                      path={ROUTES.JOBS}
                      component={DashboardPage}
                    />

                    <ProtectedRoute
                      path={ROUTES.ANALYTICS}
                      component={AnalyticsPage}
                    />

                    <ProtectedRoute
                      path={ROUTES.ROOSTERLINKS_DASHBOARD}
                      component={MeetingLinkContainer}
                    />
                    <ProtectedRoute
                      path={ROUTES.ROOSTERLINKS_BOOKINGS}
                      component={DashboardPage}
                    />

                    <Route path={ROUTES.SUPPORT} component={SupportPage} />

                    <ProtectedRoute
                      path={ROUTES.PROSPECTLINKS_DASHBOARD}
                      component={MeetingLinkContainer}
                    />
                    <ProtectedRoute
                      path={ROUTES.PROSPECTLINKS_BOOKINGS}
                      component={DashboardPage}
                    />

                    <Route
                      path={ROUTES.DOCUMENTATION}
                      component={DocumentationPage}
                    />
                    <OpenRoute
                      path={ROUTES.GOOGLEDISCLOSURE}
                      component={GoogleDisclosurePage}
                    />
                    <OpenRoute
                      path={ROUTES.GOOGLESCOPES}
                      component={GoogleScopesPage}
                    />
                    <OpenRoute
                      path={ROUTES.ZOOMSUPPORT}
                      component={ZoomSupportPage}
                    />
                    <OpenRoute
                      path={ROUTES.WEBEXSUPPORT}
                      component={WebexSupportPage}
                    />

                    <OpenRoute
                      path={ROUTES.CONFIGURATION}
                      component={ConfigurationPage}
                    />
                    <OpenRoute
                      path={ROUTES.PRIVACYPOLICY}
                      component={PrivacyPolicyPage}
                    />
                    <OpenRoute
                      path={ROUTES.TERMSANDCONDITIONS}
                      component={TermsAndConditionsPage}
                    />
                    <OpenRoute
                      path={ROUTES.DEMOVIDEO}
                      component={DemoVideoPage}
                    />
                    <OpenRoute
                      path={ROUTES.TRAININGVIDEO}
                      component={TrainingVideoPage}
                    />
                    <OpenRoute path={ROUTES.ABOUT} component={AboutPage} />
                    <OpenRoute path={ROUTES.CONTACT} component={ContactPage} />
                    <OpenRoute
                      path={ROUTES.THANKYOU}
                      component={ThankYouPage}
                    />
                    <OpenRoute
                      path={ROUTES.NOTAUTHORIZED}
                      component={NotAuthorizedPage}
                    />
                    <ProtectedAdminRoute
                      path={ROUTES.CLIENTMIGRATION}
                      component={ClientMigrationPage}
                    />
                    <ProtectedAdminRoute
                      path={ROUTES.TRANSLATIONMANAGEMENT}
                      component={TranslationManagementPage}
                    />
                    <ProtectedAdminRoute
                      path={ROUTES.CLIENTDASHBOARD}
                      component={ClientDashboardPage}
                    />
                    <OpenRoute
                      path={ROUTES.LINKUNAVAILABLE}
                      component={LinkUnavailablePage}
                    />
                    <OpenRoute
                      path={ROUTES.JOBUNAVAILABLE}
                      component={JobUnavailablePage}
                    />
                    <Redirect exact from="/" to={ROUTES.SIGNIN} />
                    <OpenRoute path="*" component={PageNotFoundPage} />
                  </Switch>
                </Stack>
                <AlertPopup />
              </ModalsProvider>
            </ThemeProvider>
          </div>
        </GAListener>
      </Router>
    </Suspense>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onSetConferencesEnabled: (enabled) =>
    dispatch({ type: SET_CONFERENCES_ENABLED_ACTION, enabled }),
  onSetSmsEnabled: (enabled) =>
    dispatch({ type: SET_SMS_ENABLED_ACTION, enabled }),
  onSetClientDocData: (clientDocData) =>
    dispatch({ type: CLIENT_DOC_DATA_SET, clientDocData }),
  onSetWebexDomain: (domain) =>
    dispatch({ type: SET_WEBEX_DOMAIN_ACTION, domain }),
  onSetClientSettings: (clientSettings) =>
    dispatch({ type: SET_CLIENT_SETTINGS_ACTION, clientSettings }),
  onSetDomainDoc: (doc) => dispatch({ type: SET_DOMAIN_DOC_ACTION, doc }),
  onSetClientLocations: (clientLocations) =>
    dispatch({ type: CLIENT_LOCATIONS_SET_ACTION, clientLocations }),
  onSetRoles: (roles) => dispatch({ type: ROLES_SET_ACTION, roles }),
  onSetSkills: (skills) => dispatch({ type: SKILLS_SET_ACTION, skills }),
  onSetConferencing: (conferencing) =>
    dispatch({ type: CONFERENCING_SET_ACTION, conferencing }),
  onSetClientReminderPreferences: (reminders) =>
    dispatch({ type: SET_CLIENT_REMINDERS_ACTION, reminders }),
  onSetClientAutomationPreferences: (automationRules) =>
    dispatch({
      type: ClientPreferenceAction.SET_CLIENT_AUTOMATION_RULES,
      payload: automationRules
    }),
  onSetClientTriggerPreferences: (triggers) =>
    dispatch({ type: SET_CLIENT_TRIGGERS_ACTION, triggers }),
  onSetClientInterviewStages: (interviewStages) =>
    dispatch({ type: SET_CLIENT_INTERVIEW_STAGES_ACTION, interviewStages })
});

export default compose(
  withAuthentication,
  connect(null, mapDispatchToProps),
  Sentry.withProfiler
)(App);
