import { Box, Typography } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose, pure } from "recompose";
import type { IHeaderProps } from "../../types/Header";
import dayjs from "dayjs";

function SandboxHeader(props: any): React.ReactElement {
  const {
    authUser,
    clientDoc,
    domainExpiryDate
  }: {
    authUser: IHeaderProps["authUser"];
    clientDoc: {
      integrations: {
        webex: {
          conference: boolean;
        };
      };
    };
    domainExpiryDate: string;
  } = props;
  const getColor = (): string => {
    let color = "#E7A150";
    if (
      authUser?.client?.includes("_gms1") ||
      !Number.isNaN(
        Number(authUser?.client?.substring(authUser.client.length - 1))
      )
    ) {
      color = "#32855F";
    } else if (authUser.client.includes("_preview")) {
      color = "#ABCC68";
    }

    return color;
  };

  const getLabel = (): string => {
    let label = "Sandbox";
    if (authUser.client?.includes("_gms1")) {
      label = "Implementation Preview";
    } else if (authUser.client.includes("_preview")) {
      label = "Sandbox Preview";
    } else if (
      !Number.isNaN(
        Number(authUser.client?.substring(authUser.client.length - 1))
      )
    ) {
      label = "Implementation";
    }
    return label;
  };
  return (
    <>
      {authUser && (
        <>
          <Box>
            {authUser?.client && import.meta.env.REACT_APP_ENV === "sandbox" ? (
              <div
                style={{
                  padding: "5px",
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "white",
                  backgroundColor: getColor(),
                  width: "100%"
                }}
              >
                &nbsp;
                {getLabel()} - {authUser.client}
              </div>
            ) : (
              ""
            )}
          </Box>
          {/*  <Box style={{ textAlign: "center", fontWeight: 800 }}>
            <Typography
              color="primary"
              sx={{ fontWeight: 600 }}
              variant="subtitle1"
            >
              ☃️ For Rooster Holiday Support, if you do not have Support access
              please email:{" "}
              <a
                target="_blank"
                href="mailto:support@roosterinc.com"
                style={{ textDecoration: "underline" }}
                rel="noreferrer"
              >
                support@roosterinc.com
              </a>
            </Typography>
          </Box> */}
          {domainExpiryDate !== "" && (
            <Box sx={{ textAlign: "center" }}>
              <Typography
                color="primary"
                sx={{ fontWeight: 600, color: "#E88504" }}
                variant="subtitle1"
              >
                {(() => {
                  const today = dayjs();
                  const deadline = dayjs(new Date(domainExpiryDate));
                  const diffDays = deadline.diff(today, "day");

                  if (diffDays <= 0) {
                    return (
                      <span style={{ color: "red" }}>
                        Your App registration has expired. Please reach out to
                        your IT team to update your app registration.
                      </span>
                    );
                  }
                  return (
                    <div>
                      Your App registration is expiring
                      <span style={{ color: "red" }}>
                        {" "}
                        in {diffDays} days.{" "}
                      </span>
                      After this date, users will be unable to schedule
                      interviews on Rooster. Please reach out to your IT team to
                      update your app registration.
                    </div>
                  );
                })()}
                {/* Your App registration is expiring on {domainExpiryDate}
                <span style={{ color: "red" }}>
                  {(() => {
                    const today = dayjs();
                    const deadline = dayjs(new Date(domainExpiryDate));
                    const diffDays = deadline.diff(today, "day");
                    return ` (in ${diffDays} days). `;
                  })()}
                </span> */}
                {/* After this date, users will be unable to schedule interviews on
                Rooster. Please reach out to your IT team to update your app
                registration. */}
              </Typography>
            </Box>
          )}
          {authUser?.client && authUser.client === "nio" && (
            <Box sx={{ textAlign: "center" }}>
              <Typography
                color="primary"
                sx={{ fontWeight: 600, color: "#E88504" }}
                variant="subtitle1"
              >
                Your Rooster subscription is ending on February 15, 2025
                <span style={{ color: "red" }}>
                  {(() => {
                    const today = dayjs();
                    const deadline = dayjs().month(1).date(15);
                    const diffDays = deadline.diff(today, "day");
                    return ` (in ${diffDays} days). `;
                  })()}
                </span>
                After this, interviews must be managed outside Rooster.
              </Typography>
            </Box>
          )}
          {authUser?.client && authUser.client === "mersana" && (
            <Box sx={{ textAlign: "center" }}>
              <Typography
                color="primary"
                sx={{ fontWeight: 600, color: "#E88504" }}
                variant="subtitle1"
              >
                Your Rooster subscription is ending on February 28, 2025
                <span style={{ color: "red" }}>
                  {(() => {
                    const today = dayjs();
                    const deadline = dayjs().month(1).date(28);
                    const diffDays = deadline.diff(today, "day");
                    return ` (in ${diffDays} days). `;
                  })()}
                </span>
                After this, interviews must be managed outside Rooster.
              </Typography>
            </Box>
          )}
          {authUser?.client && authUser.client === "servicetitan" && (
            <Box sx={{ textAlign: "center" }}>
              <Typography
                color="primary"
                sx={{ fontWeight: 600, color: "#E88504" }}
                variant="subtitle1"
              >
                Your Rooster subscription is ending on March 14, 2025
                <span style={{ color: "red" }}>
                  {(() => {
                    const today = dayjs();
                    const deadline = dayjs().month(2).date(14);
                    const diffDays = deadline.diff(today, "day");
                    return ` (in ${diffDays} days). `;
                  })()}
                </span>
                After this, interviews must be managed outside Rooster.
              </Typography>
            </Box>
          )}
          {authUser?.client && authUser.client === "global" && (
            <Box sx={{ textAlign: "center" }}>
              <Typography
                color="primary"
                sx={{ fontWeight: 600, color: "#E88504" }}
                variant="subtitle1"
              >
                As of June 7, 2025, your Rooster contract will no longer
                auto-renew. To ensure uninterrupted service, please contact{" "}
                <span style={{ textDecoration: "underline" }}>
                  nevine.eltonbary@roosterinc.com{" "}
                </span>
              </Typography>
            </Box>
          )}
        </>
      )}
    </>
  );
}

const mapStateToProps = (state: any) => ({
  authUser: state.sessionState.authUser,
  clientDoc: state.clientDocDataState.clientDocData
});

export default compose(
  pure,
  withRouter,
  connect(mapStateToProps)
)(SandboxHeader);
